<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
						<h5>Personnel Rates ({{count}})</h5>
			</CCol>
	     		 <CCol lg="6">
					   <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('personnel_rates').create"
						size="sm" style="margin-right: 10px" 
						color="success" @click="toggleModal()" 
						class="float-lg-right"><i class="fa fa-plus"></i>Add Personnel Rate
					</CButton>
					<CButton 
						v-if="config.getPermission('personnel_rates').download"
						size="sm" style="margin-right: 10px" 
						color="btn btn-outline-primary" @click="download" 
						class="float-lg-right"><i class="fa fa-download"></i>Download
					</CButton>

		      
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	     
		<CCol lg="12">  
			<div class="table-responsive-sm table-responsive-md table-responsive-lg">
	      		<CDataTable
				   :loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					striped
					outlined
					
					
			    >  
		
			      	<template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            > 
								<CDropdownItem v-if="config.getPermission('personnel_rates').view" @click="viewModal(item)"><i class="fa fa-eye"> View </i></CDropdownItem> 
				                <CDropdownItem v-if="config.getPermission('personnel_rates').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit </i></CDropdownItem>
				                <CDropdownItem v-if="config.getPermission('personnel_rates').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
				            	
				            </CDropdown>
				        </td>
			      	</template>
			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        />
			</div>
	      	</CCol>
	    </CRow>
	    <CModal :title="title" :show.sync="formModal" color="info" size="lg">
			
           	<form @submit.prevent="submit">
				  
				  
					
           		<CRow>
					<CCol lg="12">
						<div class="form-group"> 
							<label class="mb-1">Customer Name<span class="text-danger"> *</span></label>
							<v-select 
								label="customer_name" 
								:options="customerList.data"
								:reduce="item => item.id"
								v-model="dataParams.customer_id"
								placeholder="-Select-"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.customer_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="6">
						<div class="form-group"> 
							<label class="mb-1">Booking Type <span class="text-danger"> *</span></label>
							<v-select 
								label="setting_name" 
								:options="bookingTypeList.data"
								:reduce="item => item.id"
								v-model="dataParams.booking_type_id"
								placeholder="-Select-"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.booking_type_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol>
					<CCol lg="6">
						<div class="form-group"> 
							<label class="mb-1">Depot <span class="text-danger"> *</span></label>
							<v-select 
								label="setting_name" 
								:options="depotList.data"
								:reduce="item => item.id"
								v-model="dataParams.depot_id"
								placeholder="-Select-"
							> 
							<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.depot_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="6">
						<label class="mb-1">Driver Rate<span class="text-danger"> *</span> </label>
						<CInput
						  label=""
		                 
		                  v-model="dataParams.delivery_rate"
							@keypress="isNumber($event)"
		                  required="true"
		                />
					</CCol>
					<CCol lg="6">
						<label class="mb-1">Driver Allowance</label>
						<CInput

						 	@keypress="isNumber($event)"
		                  v-model="dataParams.driver_allowance" 
		                />
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="6">
						<label class="mb-1">Helper Rate<span class="text-danger"> *</span> </label>
						<CInput
		                  
		                  v-model="dataParams.helper_rate"
							@keypress="isNumber($event)"
		                  required="true"
		                />
					</CCol>
					<CCol lg="6">
						<label class="mb-1">Helper Allowance</label>
						<CInput
		                
						 	@keypress="isNumber($event)"
		                  v-model="dataParams.helper_allowance" 
		                />
					</CCol>
				</CRow> 
				
	            <div slot="footer" class="w-100">
					<CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                  Save
	                </CButton>
	            	<CButton size="sm" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	              
	                
	            </div>
				  
           	</form>
			   
			 
           	<div slot="footer" class="w-100"></div>
        </CModal>
        <CModal title="Personnel Rate Details" :show.sync="showViewModal" color="info" size="md">
					
        	<div class="p-2">
        		<CRow class="mb-2">
	        		<CCol lg="4">
	        			Customer Name:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.customer_id_label}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Booking Type:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>
							{{dataParams.booking_type_id_label}}
						</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Depot:
	        		</CCol>
	        		<CCol lg="8">
						<strong>{{dataParams.depot_id_label == undefined ? "-" : dataParams.depot_id_label}}</strong>
	        		
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Driver Rate:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.delivery_rate}}
						</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Driver Allowance:
	        		</CCol>
	        		<CCol lg="8">
						<strong>
						{{dataParams.driver_allowance_label == "" ? "-" : dataParams.driver_allowance_label}}
						</strong>
	        			
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Helper Rate:
	        		</CCol>
	        		<CCol lg="8">
						<strong>
							{{dataParams.helper_rate == "" ? "-" : dataParams.helper_rate}}
						</strong>
	        			
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Helper Allowance:
	        		</CCol>
	        		<CCol lg="8">
						<strong>
							{{dataParams.helper_allowance_label == "" ? "-" : dataParams.helper_allowance_label}}
						</strong>
	        			
	        		</CCol>
	        	</CRow>
        	</div>
		
			<div slot="footer" class="w-100">
	        </div>
        	
		
        </CModal>
		</CCard>
		
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
	mounted(){ 
		this.getData(); 
		this.getCustomerList();
		this.getBookingTypeList();
		this.getDepotList();
	},
	data(){
		return{
			count:0,
			config,
			isLoading: false,
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",
			dataParams: {
				booking_type_id: "",
				depot_id: "",
				customer_id: "",
				delivery_rate: "",
				driver_allowance: "",
				helper_rate: "",
				helper_allowance: ""
			},
			fields: [
				{
					key: 'customer_id_label', 
					label: 'Customer'
				},
				{
					key: 'booking_type_id_label', 
					label: 'Booking Type'
				},
				{
					key: 'depot_id_label', 
					label: 'Depot'
				},
				{
					key: 'delivery_rate', 
					label: 'Driver Rate'
				},
				{
					key: 'driver_allowance_label', 
					label: 'Driver Allowance'
				},
				{
					key: 'helper_rate', 
					label: 'Helper Rate'
				},
				{
					key: 'helper_allowance_label', 
					label: 'Helper Allowance'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPage: 1,
			currentPageInactive: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				customer_id: "",
				booking_type_id: "",
				depot_id: "", 
			},
			customerList: {
				data: []
			},
			bookingTypeList: {
				data: []
			},
			depotList: {
				data: []
			}
		}
	},
	name: 'Tables',
	components: {vSelect, Search},
	methods: {
		updatePage(data){
			this.currentPage = data;
			this.getData();
		}, 

		updatePageInactive(data){
			this.currentPageInactive = data;
			this.getDataInactive();
		}, 

	    toggleModal(){
	    	this.formModal = !this.formModal;
			this.title = "ADD PERSONNEL RATE";
	    },

	    submit(){
	    	var ax = {};
	    	if(this.dataParams.driver_allowance != null){
	    		if(this.dataParams.driver_allowance.trim() == ""){
		    		this.dataParams.driver_allowance = null;
		    	}
	    	}
	    	if(this.dataParams.helper_allowance != null){
	    		if(this.dataParams.helper_allowance.trim() == ""){
		    		this.dataParams.helper_allowance = null;
		    	}
	    	}
	    	
	    	if(this.dataParams.depot_id == ""){
	    		this.dataParams.depot_id = null;
	    	}
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/personnel_rate/"+this.selectedRow.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/personnel_rate", this.dataParams)
	    	}
	    	ax.then(response => {
	    		var text = 'Personnel rate successfully added!';
	    		if(this.editMode){
	    			text = 'Personnel rate successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					booking_type_id: "",
					depot_id: "",
					customer_id: "",
					delivery_rate: "",
					driver_allowance: "",
					helper_rate: "",
					helper_allowance: ""
				}
	    		this.getData();
	    	})
	    },

	    search(event){
	    	this.filter = event; 
	    	this.getData();
	    	this.getDataInactive();
	    },

	    getData(){
			this.isLoading=true;

	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	}

	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}

	    	var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}
	    	axios.get(config.api_path+'/personnel_rate?depot_id='+depot_id+'&booking_type_id='+booking_type_id+'&customer_id='+customer_id+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{
	    			if(value.depot_id == null){
	    				value.depot_id_label = "N/A";
	    			}
	    			else{
	    				value.depot_id_label = value.depot.setting_name;
	    			}

	    			value.driver_allowance_label = value.driver_allowance
	    			if(value.driver_allowance == null){
	    				value.driver_allowance_label = "N/A"
	    			}

	    			value.helper_allowance_label = value.helper_allowance
	    			if(value.helper_allowance == null){
	    				value.helper_allowance_label = "N/A"
	    			}
	    			value.customer_id_label = value.customer.customer_name;
					value.booking_type_id_label = value.booking_type.setting_name;
	    			return value;
	    		}); 
				this.count= response.data.total
	    		console.log(this.dataList.data)
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    }, 

	    updateModal(item){
			this.title = "Update Personnel Rate";
	    	this.selectedRow = item;
	    	this.dataParams = {...item};
	    	this.formModal = true;
	    	this.editMode = true;

	    },

	    viewModal(item){

	    	this.selectedRow = item;
	    	this.dataParams = item;
	    	this.showViewModal = true; 

	    },

	    deleteRow(item){
	    	Swal.fire({
				title: 'Are you sure you want to delete this personnel rate?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/personnel_rate/'+item.id)
			    	.then(response => {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "Personnel rate successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    },

	    updateStatus(data){
	    	this.dataParams.status = data;
	    },

	    getCustomerList(){

	    	axios.get(config.api_path+'/reference/customer-list', {
				params: {
					show_all : true
				}
			})
	    	.then(response => {
	    		this.customerList = response.data; 
             		
	    	})
	    }, 

	    getBookingTypeList(){

			axios.get(config.api_path+'/reference/settings-list',{
            params:{
            setting_type:'booking_type'
              }
            })
          .then(response => {
          this.bookingTypeList = response.data; 
        })

     }, 

	    getDepotList(){

			axios.get(config.api_path+'/reference/settings-list',{
            params:{
            setting_type:'depot'
              }
            })
          .then(response => {
          this.depotList = response.data; 
        })

     }, 

	    updateVendorType(data){
	    	this.dataParams.vendor_type = data;
	    },

	    updateTaxType(data){
	    	this.dataParams.tax_type = data;
	    },

	    download(){ 
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	}

	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}

	    	var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}
	this.$showLoading(true)
	    	axios.get(config.api_path+'/reports/personnel_rate?depot_id='+depot_id+'&booking_type_id='+booking_type_id+'&customer_id='+customer_id)
	    	.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    },
		

 	}
}
</script>
